import React from 'react';
import csc from 'country-state-city';
import Select from 'components/form-elements/Select';
import TextInput from 'components/form-elements/TextInput';
import styles from './requestCredentialsModal.module.scss';

const ContactInformation = ({ country, setFieldValue }) => {
  const countries = csc.getAllCountries().map(country => ({
    value: country.name,
    label: country.name,
    id: country.id,
  }));

  const currentCountry = countries.find(c => c.label === country)?.id || '';
  const states = csc
    .getStatesOfCountry(currentCountry)
    .map(state => ({ value: state.name, label: state.name, id: state.id }));

  return (
    <div className="row">
      <div className="col-12">
        <h3 className={styles.subHeading}>Company Information</h3>
      </div>
      <div className="col-sm-8 mb-4">
        <TextInput
          name="firstAddress"
          placeholder="Address 1*"
          showErrorMessages={false}
        />
      </div>

      <div className="col-sm-4 mb-4">
        <TextInput name="secondAddress" placeholder="Address 2" />
      </div>

      <div className="col-sm-4 mb-4">
        <Select
          name="country"
          data={countries}
          placeholder="Country&#42;"
          onChangeHandler={() => setFieldValue('state', '')}
        />
      </div>
      <div className="col-sm-4 mb-4">
        <TextInput name="city" placeholder="City&#42;" />
      </div>
      <div className="col-sm-4">
        <div className="row">
          <div className="col-sm-6 mb-4">
            <Select name="state" data={states} placeholder="State&#42;" />
          </div>
          <div className="col-sm-6 mb-4">
            <TextInput name="zipcode" placeholder="Zipcode&#42;" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInformation;
