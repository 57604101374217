import React from 'react';
import TextInput from 'components/form-elements/TextInput';
import styles from './requestCredentialsModal.module.scss';

const PersonalInformation = () => (
  <div>
    <div className="row">
      <div className="col-12">
        <h3 className={styles.subHeading}>Personal Information</h3>
      </div>
      <div className="col-sm-4 mb-4">
        <TextInput
          name="firstName"
          placeholder="First Name*"
          showErrorMessages={false}
        />
      </div>
      <div className="col-sm-4 mb-4">
        <TextInput
          name="lastName"
          placeholder="Last Name*"
          showErrorMessages={false}
        />
      </div>
      <div className="col-sm-4 mb-4">
        <TextInput
          name="jobTitle"
          placeholder="Job Title*"
          showErrorMessages={false}
        />
      </div>
      <div className="col-sm-4 mb-4">
        <TextInput
          name="email"
          placeholder="Email*"
          showErrorMessages={false}
        />
      </div>
    </div>
  </div>
);

export default PersonalInformation;
