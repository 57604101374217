import React, { useEffect } from 'react';
import Layout from 'components/Layout';
import SEO from 'components/seo';
import { Form, Formik } from 'formik';
import Button from 'components/Button';
import AttendAuction from 'features/requestCredentials/AttendAuction';
import PersonalInformation from 'features/requestCredentials/PersonalInformation';
import PhoneNumberInfo from 'features/requestCredentials/PhoneNumberInfo';
import ContactInformation from 'features/requestCredentials/ContactInformation';
import CompanyOnlineLinks from 'features/requestCredentials/CompanyOnlineLinks';
import RequestPressCredentials from 'features/requestCredentials/RequestPressCredentials';
import AdditionalQuestions from 'features/requestCredentials/AdditionalQuestions';
import {
  INITIAL_VALUES,
  VALIDATION_SCHEMA,
} from 'components/utils/requestCredentials';
import styles from './requestCredentialsModal.module.scss';
import {
  getUpcomingAuctions,
  getUpcomingSubEvent,
} from 'services/graphql/queries/auctions/formatedAuctions';
import { useStaticQueryGetAuctions } from 'services/graphql/queries/auctions';
import moment from 'moment';
import { getEventDateAverage } from 'shared/helpers/auctions';
import { useMutation } from '@apollo/client';
import { SEND_REQUEST_CREDENTIALS_EMAIL } from 'services/graphql/queries/general-emails';
import Spinner from 'components/Spinner';
import { navigate } from 'gatsby';

const RequestCredentialsModal = ({ toggle }) => {
  const { subEventAuction } = useStaticQueryGetAuctions({
    todayDate: moment().format('YYYY-MM-DDTHH:mm'),
  });

  const validSubEvents =
    subEventAuction?.length && getUpcomingSubEvent(subEventAuction);

  const auctions =
    validSubEvents?.length &&
    getUpcomingAuctions(validSubEvents)
      .filter(
        auction =>
          auction.webpage__auction && auction.eventStatus !== 'EventCancelled'
      )
      .map(auction => {
        const date =
          auction.eventStatus === 'EventPostponed'
            ? 'Postponed'
            : auction.subEvents?.length &&
              getEventDateAverage(auction.subEvents);
        return { label: `${auction.name} - ${date}`, selected: false };
      });
  INITIAL_VALUES.requestPressCredentials = auctions;

  const [
    sendEmail,
    { error: emailError, data: emailData, loading: isSendingEmail },
  ] = useMutation(SEND_REQUEST_CREDENTIALS_EMAIL);

  const sendEmailError = emailError
    ? emailError.message
    : emailData &&
      emailData.RequestCredentialsEmail &&
      emailData.RequestCredentialsEmail.errorMessages
    ? emailData.RequestCredentialsEmail.errorMessages.join(',')
    : null;

  useEffect(() => {
    if (emailError !== undefined || emailData !== undefined) {
      const emailSent = emailData?.RequestCredentialsEmail?.code
        ?.toString()
        .startsWith('2')
        ? true
        : false;
      navigate('/media', {
        state: { emailSent: emailSent, error: sendEmailError },
      });
    }
  }, [emailError, emailData, sendEmailError]);

  return (
    <>
      {isSendingEmail && <Spinner />}
      <Layout>
        <SEO title="Request Credentials" />
        <div className={styles.requestCredentialsModal}>
          <div className={styles.header}>
            <h3>Request Credentials</h3>
          </div>
          <div className={styles.body}>
            <Formik
              initialValues={INITIAL_VALUES}
              validationSchema={VALIDATION_SCHEMA}
              onSubmit={async values => {
                const { requestPressCredentials, ...fields } = values;
                fields.auctions = requestPressCredentials
                  .filter(request => request.selected === true)
                  .map(request => {
                    return request.label;
                  });
                await sendEmail({
                  variables: { fields: fields },
                });
              }}
            >
              {({
                values: {
                  attendAuctionsInPast,
                  country,
                  companyOnlineLinks,
                  requestPressCredentials,
                },
                isValid,
                dirty,
                setFieldValue,
                submitForm,
                isSubmitting,
              }) => {
                return (
                  <Form>
                    <div className={styles.bodyContent}>
                      <AttendAuction
                        attendAuctionsInPast={attendAuctionsInPast}
                        setFieldValue={setFieldValue}
                      />
                      <PersonalInformation />
                      <PhoneNumberInfo />
                      <ContactInformation
                        country={country}
                        setFieldValue={setFieldValue}
                      />
                      <CompanyOnlineLinks
                        companyOnlineLinks={companyOnlineLinks}
                      />
                      <RequestPressCredentials
                        requestPressCredentials={requestPressCredentials}
                        setFieldValue={setFieldValue}
                      />
                      <AdditionalQuestions />
                    </div>
                    <div className={styles.footer}>
                      <Button
                        type="app-primary-button"
                        handleClick={async () => await submitForm()}
                        disabled={isSubmitting || !(isValid && dirty)}
                      >
                        Send
                      </Button>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </Layout>
    </>
  );
};

export default RequestCredentialsModal;
